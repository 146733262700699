import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import HeaderSearch from "./HeaderSearch";

const metaTag = document.querySelector('meta[name="page-type"]');
const pageType = metaTag ? metaTag.content : null;

if (pageType === 'game') {
  // 掛載 game.html 相關的組件
  ReactDOM.createRoot(document.getElementById('search-root')).render(<HeaderSearch />);
} else {
  // 掛載 home.html 相關的組件
  ReactDOM.createRoot(document.getElementById('root')).render(<App />);
}




// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );



// 檢查當前頁面是否是 game.html
// const isGamePage = document.location.pathname.includes('game.html');
// const isGamePage = /game\/\d+/.test(document.location.pathname);
//
//
// if (isGamePage) {
//   // 如果當前是 game.html，則只掛載 HeaderSearch
//   ReactDOM.render(<HeaderSearch />, document.getElementById('search-root'));
// } else {
//   // 否則掛載整個 App
//   ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// }




