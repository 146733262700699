import React, { useRef, useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

function GameList({ games, category }) {
  const gameGrid = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [initialScroll, setInitialScroll] = useState(0);

  const updateArrows = () => {
    const grid = gameGrid.current;
    setShowLeftArrow(grid.scrollLeft > 0);
    setShowRightArrow(grid.scrollLeft < grid.scrollWidth - grid.offsetWidth);
  };

  const handleSwiping = ({ deltaX }) => {
    // 不直接更新 grid.scrollLeft，只是計算應該滑動到哪裡
    const newScrollLeft = initialScroll - (deltaX * 4);
    // 在這裡不設置 grid.scrollLeft
  };

  const handlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: ({ deltaX }) => {
      // 在滑動結束後更新 grid.scrollLeft 和 initialScroll
      const grid = gameGrid.current;
      const newScrollLeft = initialScroll - (deltaX * 4);
      grid.scrollLeft = newScrollLeft;
      setInitialScroll(newScrollLeft);
    },
    delta: 10,
  });

  useEffect(() => {
    const grid = gameGrid.current;
    updateArrows();
    grid.addEventListener('scroll', updateArrows);

    return () => {
      grid.removeEventListener('scroll', updateArrows);
    };
  }, []);

  return (
    <section className="games" {...handlers}>
      <h1 className="featured-games">{category}</h1>
      <button className={`scroll-button left-scroll ${showLeftArrow ? '' : 'hidden'}`} onClick={() => gameGrid.current.scrollBy(-400, 0)}>＜</button>
      <div id="gameGrid" ref={gameGrid} className="game-grid">
        {games.map((game, index) => (
          <a href={`/games/${game.folder_name}/`} className="game-card-link" key={game.id}>
            <div className="game-card" style={{ backgroundImage: `url('/static/${game.folder_name}/${game.folder_name}.png')` }}>
              <h3 className="game-title">{game.title}</h3>
            </div>
          </a>
        ))}
      </div>
      <button className={`scroll-button right-scroll ${showRightArrow ? '' : 'hidden'}`} onClick={() => gameGrid.current.scrollBy(400, 0)}>＞</button>
    </section>
  );
}

export default GameList;
