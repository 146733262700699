import React, {useState, useEffect, useRef, useContext} from 'react';
import AppContext from './AppContext';

function SearchBar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const searchBarRef = useRef(null);
    const { handleSearchAll } = useContext(AppContext);

    const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length > 1) {
        fetch(`/api/games/search/${event.target.value}`)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok');
            }
        })
        .then(data => {
            setSearchResults(data);
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation: ', error);
        });
    }
    };

    const handleResultClick = (game) => {
        window.location.href = `/games/${game.folder_name}/`;
        // window.location.href = `/${game.id}/`;
    };

    const handleClickOutside = (event) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
            setSearchTerm('');
            setSearchResults([]);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="search-bar" ref={searchBarRef}>
            <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search games..."
                className="search-bar-input"
            />
            {searchResults.length > 0 && (
                <ul className="search-results">
                    {searchResults.slice(0, 7).map(game => (
                        <li key={game.id} onClick={() => handleResultClick(game)}>
                            <img src={`/static/${game.folder_name}/${game.folder_name}.png`} alt={game.title} className="search-results-img"/>
                            <span>{game.title}</span>
                        </li>
                    ))}
                    <li onClick={()=> {
                        handleSearchAll(searchTerm);
                        setSearchTerm('');
                        setSearchResults([]);
                    }}>
                        <span>Search All</span>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default SearchBar;
