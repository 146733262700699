// App.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import GameList from "./GameList";
import AppContext from "./AppContext";
import Sidebar from "./Sidebar";
import HeaderSearch from './HeaderSearch';


const categories = ['New Games', 'Top Games', 'Action', 'Adventure', 'Escape', 'Puzzle', 'Golf', 'Casual', 'FPS', 'Sports', 'All'];

function App() {
    const [games, setGames] = useState([]);
    const [categoryGames, setCategoryGames] = useState([]);
    const [category, setCategory] = useState('All');
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [searchGames, setSearchGames] = useState([]);


    const handleSearchAll = (term) => {
    setIsSearchMode(true);
    fetch(`/api/games/search/${term}`)
        .then(response => response.json())
        .then(data => {
            setSearchGames(data);
        })
        .catch(error => console.error(error));
};



    const fetchGamesByCategory = (selectedCategory) => {
        setCategory(selectedCategory);  // 更新當前所選的分類
        fetch(`/api/categories/${selectedCategory}`)
            .then(response => response.json())
            .then(data => {
                // console.log("Data from API: ", data); // 此處檢查 API 返回的數據
                setGames(data);
            })
            .catch(error => console.error(error));
    };

    useEffect(() => {
        // 循環遍歷每個類別並獲取相關的遊戲資料
        Promise.all(categories.map(category =>
            fetch(`/api/categories/${category}`)
                .then(response => response.json())
                .then(data => ({category, games: data}))
        ))
        .then(data => setCategoryGames(data))
        .catch(error => console.error(error));
    }, []);

    return (
        <AppContext.Provider value={{ fetchGamesByCategory, handleSearchAll }}>
            <div className="App">
                <HeaderSearch />
                {isSearchMode
                ? <GameList games={searchGames} category="Search Results" />
                : category === 'All'
                    ? categoryGames.map(categoryData => <GameList games={categoryData.games} category={categoryData.category} />)
                    : <GameList games={games} category={category} />
            }
                {ReactDOM.createPortal(<Sidebar />, document.getElementById('sidebar-root'))}
            </div>
        </AppContext.Provider>
    );
}

export default App;
