import React, { useState, useContext } from 'react';
import AppContext from './AppContext';
import './sidebar.css';



function Sidebar() {
    const [isShown, setIsShown] = useState(false);
    const [isTransitionShown, setIsTransitionShown] = useState(false);
    const categories = ['New Games', 'Top Games', 'Action', 'Adventure', 'Escape', 'Puzzle', 'Golf',
        'Casual', 'FPS', 'Racing', 'All' /* ...其他分類 */];


    const context = useContext(AppContext);



    const handleCategoryClick = (category) => {
        setIsTransitionShown(true);
        setTimeout(() => setIsTransitionShown(false), 100); // 100毫秒後移除特效
        context.fetchGamesByCategory(category);  // <-- 修改這裡
        if (window.innerWidth <= 2000) {
            setIsShown(false);
        }
    };

    return (
        <div>
            <div className={`screen-transition ${isTransitionShown ? 'shown' : ''}`}></div>
            <button className="hamburger" onClick={() => setIsShown(!isShown)}>&#9776;</button>
            <div className={`sidebar ${isShown ? 'show' : ''}`}>
                <ul>
                    {categories.map(category => (
                        <li key={category}>
                            <a href="#" onClick={(event) => {
                                event.preventDefault();  // 阻止預設行為
                                handleCategoryClick(category);
                            }}>
                                {category}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}


export default Sidebar;
