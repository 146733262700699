import React from 'react';

const AppContext = React.createContext({
    fetchGamesByCategory: () => {},
    setSearchResults: () => {},
    setSearchMode: () => {},
    handleSearchAll: () => {},
});

export default AppContext;

