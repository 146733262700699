import React from 'react';
import ReactDOM from 'react-dom';
import SearchBar from './SearchBar';

function HeaderSearch() {
  return ReactDOM.createPortal(
    <SearchBar />,
    document.getElementById('search-root') // 指定掛載的 DOM 節點
  );
}

export default HeaderSearch;


